

import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import grading from '../assets/images/grading.jpg'
import LaborWarranty from '../components/LaborWarranty'

const Grading = (props) => (
    <Layout>
        <Helmet>
            <title>Site Clearing, Grading, & Erosion Control</title>
            <meta name="description" content="We provide exceptional Site Clearing, Grading, & Erosion Control services in the South Eastern part of the USA!" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Site Clearing, Grading, & Erosion Control</h1>
                        <p>$7000 per Acre (Erosion Control, add $2000)</p>
                    </header>
                    
                    <span className="image main">
                    <img src={grading} alt="" /></span><br /><br />
                    
                    <header className="major">
                        <h3>What's Included</h3>
                    </header>
                    <p>...</p><br /><br />
                    
                    <header className="major">
                        <h3>Pricing</h3>
                    </header>
                    <ul>
                        <li>How We Price It: </li>
                        <li>Competitor Pricing: </li>
                    </ul><br /><br />
                    
                    <header className="major">
                        <h3>Warranty Information</h3>
                    </header>
                    <ul>
                        <li>Materials Warranty: </li>
                        <li>{LaborWarranty}</li>
                    </ul><br />
                </div>
            </section>
        </div>

    </Layout>
)

export default Grading